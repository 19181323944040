

























import { Component, Prop, Vue } from 'vue-property-decorator';
import { Word } from '../word';

@Component
export default class WordComponent extends Vue {
  @Prop() private word!: Word;

  get language() : string {
    return this.word?.language || 'Unknown';
  }

  get languageClass() : string {
    const { language } = this;
    return language.split(/\s+/)[0].replace(/[^a-z0-9]+/g, '-').toLowerCase();
  }

  get languageForDisplay() : string {
    const { language } = this;
    const displayLang = language.split(/\s+/)[0];
    return displayLang === language ? displayLang : `${displayLang}*`;
  }
}
