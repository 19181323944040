





























import { Component, Vue } from 'vue-property-decorator';
import WordComponent from '@/components/WordComponent.vue';
import Search from '@/components/Search.vue';
import rawWords from '../gklatwords.json';
import { Word } from './word';

// eslint-disable-next-line @typescript-eslint/ban-types
export function debounce(fn: Function, delay: number): () => void {
  let timeoutID = 0;

  // eslint-disable-next-line func-names, @typescript-eslint/no-explicit-any
  return function (...args: any[]) {
    clearTimeout(timeoutID);

    timeoutID = setTimeout(() => {
      fn(...args);
    }, delay);
  };
}

const words = rawWords.map((w, idx) => ({ ...w, id: idx }));

@Component({
  components: {
    WordComponent,
    Search,
  },
})
export default class App extends Vue {
  search: string;

  onSearchChange: () => void;

  constructor() {
    super();
    this.search = '';

    this.onSearchChange = debounce((value: string) => {
      this.updateSearch(value);
    }, 150);
  }

  get found(): Word[] {
    const { search } = this;
    if (!search) {
      return [words[Math.floor(Math.random() * words.length)] as Word];
    }
    console.log('found', search);
    const pattern = new RegExp(`.*${search}.*`, 'i');
    return words
      .map((w) => {
        let score = 0;

        const exactMeaningScore = 50
          * w.meaning.split(/\s+/).filter((meaning) => meaning === search).length;
        const exactStemScore = 30 * w.stems.filter((stem) => stem === search).length;
        const exactExamplesScore = 5 * w.examples.filter((eg) => eg === search).length;

        const meaningScore = 15
          * w.meaning.split(/\s+/).filter((meaning) => pattern.test(meaning))
            .length;
        const stemScore = 10 * w.stems.filter((stem) => pattern.test(stem)).length;
        const examplesScore = 1 * w.examples.filter((eg) => pattern.test(eg)).length;

        score = exactStemScore
          + exactMeaningScore
          + exactExamplesScore
          + stemScore
          + meaningScore
          + examplesScore;

        return { ...w, score };
      })
      .filter((w) => w.score > 0)
      .sort((a, b) => b.score - a.score) as Word[];
  }

  updateSearch(search: string): void {
    console.log('update search', search);
    this.search = search;
  }
}
